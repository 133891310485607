import styled from 'styled-components';

export const ContactRow = styled.div`
  @media (min-width: 575px) {
    display: flex;
    flex-direction: row;
  }
`;

const ContactForm = styled.form`
  display: flex;
  align-items: justify;
  justify-content: space-between;
  margin: 0 auto;
  flex-direction: column;

  .reusecore__input {
    flex: 1;
    padding: 10px 5px;
    margin-right: 20px;
    @media (max-width: 575px) {
      margin: 0 0 20px 0;
      width: 100%;
    }
    .field-wrapper {
      input {
        min-height: 45px;
      }
    }
    &.is-material {
      label {
        font-size: 14px;
        top: 14px;
        font-weight: 500;
        color: rgba(51, 61, 72, 0.4);
      }
      &.is-focus {
        label {
          top: -12px;
        }
      }
    }
  }

  .reusecore__button {
    flex-shrink: 0;
    transition: all 0.3s ease;
    @media (max-width: 575px) {
      width: 100%;
    }
    &:hover {
      box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
    }
  }
`;

export default ContactForm;
