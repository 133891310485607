import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { themeGet } from 'styled-system';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Roboto', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto', sans-serif;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;    
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #2aa275;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #2aa275;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #2aa275;
        transform: rotate(45deg);
        display: block;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            color: #000;
            &:hover {
              color: #2aa275;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #2aa275;
      }
    }
  }

  .saas_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #edcd37;
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #edcd37;
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
`;

export const SectionHeader = styled.header`
  text-align: center;
  padding-bottom: 70px;
  @media only screen and (max-width: 1440px) {
    padding-bottom: 56px;
  }
  @media only screen and (max-width: 1200px) {
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 480px) {
    text-align: left;
  }

  h5 {
    color: ${themeGet('colors.link', '#352FD9')};
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 15px;
    @media only screen and (max-width: 1200px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
  }

  h2 {
    color: ${themeGet('colors.heading', '#191919')};
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
    margin: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 30px;
      line-height: 46px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 28px;
      line-height: 42px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 35px;
    }
  }
`;

/* ------------------------------------ */
// style for circle loader
/* ------------------------------------ */
const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`;

const grow = keyframes`
	50% {
		transform: scale(1);
	}
`;

export const CircleLoader = styled.div`
  animation: ${rotate} 3s linear infinite;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  transform-origin: bottom center;

  .circle {
    animation: ${grow} 1.5s linear infinite;
    background-color: ${themeGet('colors.primary', '#FDEF00')};
    border-radius: 50%;
    display: inline-block;
    margin: -9px;
    height: 40px;
    width: 40px;
    transform: scale(0);

    &:nth-of-type(2) {
      animation-delay: 0.75s;
      background-color: ${themeGet('colors.white', '#ffffff')};
    }
  }

  &.alt {
    .circle {
      &:nth-of-type(2) {
        background-color: ${themeGet('colors.heading', '#191919')};
      }
    }
  }
`;
