import styled from 'styled-components';
import { themeGet } from 'styled-system';

const FooterArea = styled.footer`
  padding: 40px 0 20px;
  background: linear-gradient(
    35deg,
    ${themeGet('colors.primary', '#09142E')} 0%,
    ${themeGet('colors.secondary', '#09142E')} 100%
  );
  @media only screen and (max-width: 1366px) {
    padding-top: 20px;
  }
  @media only screen and (max-width: 667px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .logo {
    img {
      width: 128px;
      height: auto;
    }
  }
`;

export const MenuArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  @media only screen and (max-width: 1366px) {
    padding-top: 10px;
  }
  > a {
    margin-right: 0;
  }
`;

export const Text = styled.a`
  color: ${themeGet('colors.lightText', '#7E7E7E')};
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 14px;
  &:hover {
    color: ${themeGet('colors.primary', '#FDEF00')};
  }
`;

export const CopyrightText = styled.p`
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
  font-size: 14px;
`;

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 20px;

  li {
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet('colors.lightText', '#7E7E7E')};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 15px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet('colors.primary', '#FDEF00')};
      }
    }
  }
`;

export default FooterArea;
