import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/Container';
import ContactForm, { ContactRow } from './contactus.style';

const ContactUsSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  imageArea,
  ImageOne,
  btnStyle,
  outlineBtnStyle,
  buttonWrapper,
}) => {
  const emptyState = {
    email: '',
    name: '',
    subject: '',
    message: '',
  };
  const [state, setState] = useState(emptyState);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [showMessage]);

  const handleOnChange = event => {
    const { name, value } = event.target;
    setState(x => ({ ...x, [name]: value }));
  };

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleContactForm = async event => {
    event.preventDefault();
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...state }),
    });
    setShowMessage(true);
    setState({ ...emptyState });
  };

  return (
    <Box {...sectionWrapper} as="section" id="contactus_section">
      <Container>
        <Box {...row}>
          <Box {...textArea}>
            <Heading {...title} content="Contact Us" />
            <Text
              {...description}
              content="Send us a quick note to let us know how we can help you. We'll get back to you as soon as possible."
            />
          </Box>
        </Box>
        <ContactForm
          onSubmit={handleContactForm}
          name="contact"
          data-netlify="true"
        >
          <ContactRow>
            <Input
              type="email"
              pattern="\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+"
              placeholder="Email address"
              icon={<Icon icon={iosEmailOutline} />}
              iconPosition="left"
              required={true}
              value={state.email}
              onChange={handleOnChange}
              name="email"
            />
            <Input
              placeholder="Your name"
              required={true}
              value={state.name}
              onChange={handleOnChange}
              name="name"
            />
          </ContactRow>
          <Input
            placeholder="Subject"
            required={true}
            value={state.subject}
            onChange={handleOnChange}
            name="subject"
          />
          <Input
            inputType="textarea"
            rows="4"
            placeholder="Message"
            required={true}
            value={state.message}
            onChange={handleOnChange}
            name="message"
          />
          <Box {...buttonWrapper}>
            <Button type="submit" title="SUBMIT" {...btnStyle} />
          </Box>
          <Text
            {...description}
            style={{
              opacity: showMessage ? '1' : '0',
              transition: 'opacity .25s ease-in-out',
            }}
            content="Thanks for submitting!"
          />
        </ContactForm>
      </Container>
    </Box>
  );
};

ContactUsSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

ContactUsSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'contactus-section',
    pt: ['20px', '40px', '60px', '80px'],
    pb: ['0px', '0px', '40px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textArea: {
    width: ['100%', '100%', '80%', '43%'],
  },
  imageArea: {
    width: ['100%', '100%', '43%'],
    mb: ['35px', '35px', '40px', '40px'],
  },
  title: {
    fontSize: ['28px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '16px', '16px', '16px'],
    color: '#5c636c',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['35px', '35px', '40px', '60px'],
  },
  ImageOne: {
    ml: 'auto',
    mr: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'secondaryWithBg',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#0f2137',
    colors: 'secondary',
  },
};

export default ContactUsSection;
