import React from 'react';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { twitter } from 'react-icons-kit/fa/twitter';
import { instagram } from 'react-icons-kit/fa/instagram';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import Logo from 'common/src/components/UIElements/Logo';
import LogoImage from 'common/src/assets/image/saasModern/logo-white.png';
import Container from 'common/src/components/UI/Container';
import FooterArea, {
  MenuArea,
  SocialList,
  Text,
  CopyrightText,
} from './footer.style';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: 'https://www.facebook.com/tepuiio',
  },
  {
    id: 2,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: 'https://twitter.com/tepui_io',
  },
  {
    id: 3,
    icon: <Icon icon={instagram} />,
    name: 'instagram',
    link: 'https://instagram.com/tepui.io',
  },
  {
    id: 4,
    icon: <Icon icon={linkedin} />,
    name: 'linkedin',
    link: 'https://linkedin.com/company/tepui-io',
  },
];

const mail = 'info@tepui.io';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea>
      <Container>
        <MenuArea>
          <Logo className="logo" href="/" logoSrc={LogoImage} title="Tepui" />
          <Text href={`mailto:${mail}`}>{mail}</Text>
          <SocialList>
            {socialLinks.map(item => (
              <li className={item.name} key={`link-key${item.id}`}>
                <a aria-label={item.name} href={item.link}>
                  {item.icon}
                </a>
              </li>
            ))}
          </SocialList>
          <CopyrightText>Copyright {year} By Tepui Inc</CopyrightText>
        </MenuArea>
      </Container>
    </FooterArea>
  );
};

export default Footer;
